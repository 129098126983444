import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css, useTheme } from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import { useFeatureFlag } from 'hooks/feature_flag'
import { selectAmazonSellerAccount } from 'actions/index'
import { useSelectedAmazonSellerAccount } from 'hooks/amazon_seller_account'

import { MARKETPLACES } from 'constants/countries'
import { TEXT_STYLES } from 'constants/text_styles'
import { SETTINGS_URLS } from 'constants/routes'

import { NakedButton } from 'ui_elements/NakedButton/NakedButton'
import { ConnectToAmazonButton } from 'src/js/containers/common/ConnectToAmazonButton'
import {
  ButtonType,
  DropdownTrigger,
  DropdownMenu,
  FlagIcon,
  Icon,
  Tooltip
} from '@junglescout/edna'
import { slideInDown, slideOutUp } from 'src/js/components/common/animations'
import { TruncatedLine } from 'ui_elements/TruncatedLine/TruncatedLine'
import { VerticalScrollingBlock } from 'ui_elements/VerticalScrollingBlock/VerticalScrollingBlock'
import { safeSegmentCall, sendSegmentTrackEvent } from 'services/segment'
import { TOP_NAV } from 'constants/activation_points/navbar'
import { sendCtaClickEvent, sendNavClickEvent } from 'helpers/segment'
import { createBanner } from 'actions/banners'
import {
  getSellerCentralHostname,
  shouldShowPpcPermissionsBanner
} from 'helpers/account'
import { Features } from 'constants/feature_names'

const Divider = styled(DropdownMenu.Divider)`
  margin: 8px 4px;
`
const Item = styled(DropdownMenu.Item)`
  ${TEXT_STYLES.H4Black};
  font-weight: 400 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  padding: 0 12px;
  height: 44px;
  justify-content: space-between;
  background-color: ${props =>
    props.selected ? props.theme.colors.grey050 : 'transparent'};
  svg {
    height: 20px;
    width: 20px;
  }
`
const CountryLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`
const AccountLabel = styled.div`
  ${TEXT_STYLES.H4Black};
  color: ${props => props.theme.colors.grey600};
  margin-bottom: 8px;
  padding: 0 8px;
  display: flex;
  align-items: center;
`
const DropdownContainer = styled(DropdownMenu)`
  padding: 12px;
  &[data-state='open'] {
    animation: ${slideInDown} 200ms ease-in-out;
  }
  &[data-state='closed'] {
    animation: ${slideOutUp} 200ms ease-in-out;
  }
`
const StyledDropdownTrigger = styled(DropdownTrigger)`
  ${props =>
    props.$isMobile &&
    css`
      background-color: ${props.theme.colors.grey900};
      border: 1px solid ${props.theme.colors.grey700};
      color: ${props.theme.colors.white};
    `}
`
const TriggerFlagIcon = styled(FlagIcon)`
  min-width: 16px;
  margin-right: 8px;
`
const ButtonWrapper = styled.div`
  padding: 8px;
`
const WarningIconButton = styled(NakedButton)`
  display: flex;
  align-items: center;
  margin-left: 10px;
`
const AccountSection = styled.section`
  &:last-of-type {
    ${Divider} {
      display: none;
    }
  }
`
const UnderlinedLink = styled.a`
  text-decoration: underline;
  color: inherit;
`
const StyledConnectToAmazonButton = styled(ConnectToAmazonButton)`
  ${props =>
    props.$isMobile &&
    css`
      background: transparent;
      border: 1px solid ${props => props.theme.colors.grey700};
      color: ${props => props.theme.colors.white};
      &:hover {
        background-color: ${props => props.theme.colors.grey800};
      }
    `}
`

const showBanners = ({
  selectedAccount,
  dispatch,
  country_code,
  admin_user,
  t
}) => {
  const _72_HOURS = 72 * 60 * 60
  const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24
  const id = 'ppc-permissions'

  const { last_successful_exports_request, region } = selectedAccount
  const now = new Date()
  const lastSuccessfulExportsRequest = new Date(last_successful_exports_request)

  const daysSinceLastExport = Math.floor(
    (now - lastSuccessfulExportsRequest) / MILLISECONDS_IN_A_DAY
  )

  const hostname = getSellerCentralHostname(region, country_code)
  const path = '/gp/account-manager/home.html/'
  const link = `${hostname}${path}`

  if (daysSinceLastExport > 30) {
    dispatch(
      createBanner({
        id,
        type: 'error',
        dismissible: false,
        message: (
          <div>
            <Trans
              t={t}
              i18nKey="account:AmazonSellerAccounts.Ppc.warningBanner2">
              Your data is inaccurate due to missing permissions—
              <UnderlinedLink href={link} target="_blank">
                please update your permissions
              </UnderlinedLink>{' '}
              soon to avoid data gaps.
            </Trans>
          </div>
        )
      })
    )
    return
  }

  dispatch(
    createBanner({
      id,
      type: 'warning',
      dismissible: true,
      showAfter: _72_HOURS,
      message: (
        <div>
          <Trans
            t={t}
            i18nKey="account:AmazonSellerAccounts.Ppc.warningBanner1"
            values={{ email: admin_user.email }}>
            Please update{' '}
            <UnderlinedLink href={link} target="_blank">
              your Amazon permissions
            </UnderlinedLink>{' '}
            to avoid data gaps. The admin owner {'{{email}}'} is responsible for
            completing this update.
          </Trans>
        </div>
      )
    })
  )
}

export const AmazonAccountDropdown = ({ isMobile, onCtaClick }) => {
  const { t } = useTranslation(['account'])
  const navigate = useNavigate()
  const { colors } = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()

  const arePpcUpdateBannersEnabled = useFeatureFlag(Features.PPC_UPDATE_BANNERS)

  const {
    user: { is_account_owner: isAccountOwner },
    membershipInfo: { hasActiveMembership, admin_user }
  } = useSelector(state => state.globalData)

  const {
    selectedAccount,
    marketplace_id,
    country_code
  } = useSelectedAmazonSellerAccount()
  const { isLoading, amazonSellerAccounts } = useSelector(
    state => state.amazonSellerAccounts
  )

  useEffect(() => {
    if (
      selectedAccount &&
      hasActiveMembership &&
      arePpcUpdateBannersEnabled &&
      shouldShowPpcPermissionsBanner(selectedAccount)
    ) {
      showBanners({
        selectedAccount,
        dispatch,
        country_code,
        admin_user,
        t
      })
    }
  }, [
    admin_user,
    arePpcUpdateBannersEnabled,
    country_code,
    dispatch,
    hasActiveMembership,
    selectedAccount,
    t
  ])

  const isOwner = isAccountOwner || !hasActiveMembership

  const noAccounts = isEmpty(amazonSellerAccounts)

  const renderCountry = (country, key, account) => {
    if (!country.country_code) {
      return null
    }

    const countryDetails = MARKETPLACES[country.country_code.toLowerCase()]

    if (!countryDetails) {
      return null
    }

    const isSelected = marketplace_id === country.marketplace_id

    return (
      <Item
        key={key}
        action={() => {
          if (!isSelected) {
            dispatch(selectAmazonSellerAccount({ country, account }))
            safeSegmentCall(() => {
              sendSegmentTrackEvent('Amazon Account Switch', {
                fromMarketplace: country_code,
                toMarketplace: country?.country_code,
                fromSellerId: selectedAccount?.merchant_id,
                toSellerId: account?.merchant_id
              })
            })
          }
        }}
        selected={isSelected}>
        <CountryLabel>
          <FlagIcon country={country.country_code} size="20px" />
          <div>{countryDetails.name}</div>
        </CountryLabel>
      </Item>
    )
  }

  const renderAccountWarning = account => {
    const ppcEnabled = account.has_ppc_access
    const mwsEnabled = account.aasm_state === 'enabled'

    if (ppcEnabled && mwsEnabled) {
      return null
    }

    const data = {
      mws: {
        tooltipText: t(
          'account:MyAccount.menu.amazonDisabledTooltip',
          'This Amazon Account needs to be re-enabled. Click this icon to head to Amazon Settings to re-enable it.'
        ),
        trackingText: 'Enable MWS Token Warning'
      },
      ppc: {
        tooltipText: t(
          'account:MyAccount.menu.ppcDisabledTooltip',
          "This Amazon account's PPC connection needs to be re-enabled. Click this icon to head to Amazon Settings to re-enable it."
        ),
        trackingText: 'Enable PPC Warning'
      }
    }

    const warningData = !mwsEnabled ? data.mws : data.ppc
    const { tooltipText, trackingText } = warningData

    return (
      <Tooltip side="top" size="large" content={tooltipText}>
        <WarningIconButton
          onClick={() => {
            setIsOpen(false)
            sendCtaClickEvent({
              location: 'Marketplace Dropdown',
              text: trackingText
            })
            navigate(SETTINGS_URLS.amazonSettings)
          }}>
          <Icon name="TOOLTIP_WARNING" color={colors.red500} />
        </WarningIconButton>
      </Tooltip>
    )
  }

  const renderAccounts = () => {
    return (
      <>
        {amazonSellerAccounts.map(account => {
          return (
            <AccountSection key={account.id}>
              <AccountLabel>
                {account.label || '-'}
                {renderAccountWarning(account)}
              </AccountLabel>
              {account.countries.map((country, countryKey) =>
                renderCountry(country, countryKey, account)
              )}
              <Divider />
            </AccountSection>
          )
        })}
      </>
    )
  }

  const renderContent = () => {
    return (
      <>
        <VerticalScrollingBlock maxHeight="552px">
          {renderAccounts()}
        </VerticalScrollingBlock>
        <ButtonWrapper>
          <ConnectToAmazonButton
            onClick={() => {
              setIsOpen(false)
              onCtaClick()
              sendCtaClickEvent({
                location: 'Marketplace Dropdown',
                text: 'Add another Amazon Account'
              })
            }}
            iconName="PLUS"
            btnType={ButtonType.TERTIARY}
            width="100%"
            margin="0"
            btnText={t(
              'account:MyAccount.menu.addAnotherAccount',
              'Add another Amazon Account'
            )}
          />
        </ButtonWrapper>
      </>
    )
  }

  const renderTriggerContent = () => {
    return (
      <>
        <TriggerFlagIcon country={country_code} size="16px" />
        <TruncatedLine>
          {selectedAccount?.label || MARKETPLACES[country_code]?.name}
        </TruncatedLine>
      </>
    )
  }

  const renderCTA = () => {
    if (!isOwner) {
      return null
    }

    return (
      <StyledConnectToAmazonButton
        $isMobile={isMobile}
        iconName="PLUS"
        width={isMobile ? '100%' : 'auto'}
        btnType={ButtonType.TERTIARY}
        btnText={t(
          'account:MyAccount.menu.connectAccount',
          'Connect an Amazon Account'
        )}
        onClick={() => {
          onCtaClick()
          sendCtaClickEvent({
            location: TOP_NAV,
            text: 'Connect an Amazon Account'
          })
        }}
      />
    )
  }

  if (isLoading) {
    return null
  }

  if (noAccounts) {
    return renderCTA()
  }

  return (
    <DropdownContainer
      open={isOpen}
      onOpenChange={showing => {
        setIsOpen(showing)
        if (showing) {
          sendNavClickEvent({
            location: TOP_NAV,
            navItem: 'Amazon Account Dropdown'
          })
        }
      }}
      width="324px"
      trigger={
        <StyledDropdownTrigger
          $isMobile={isMobile}
          width={isMobile ? '100%' : '220px'}
          aria-label="Select Amazon Account">
          {renderTriggerContent()}
        </StyledDropdownTrigger>
      }
      align="end"
      sideOffset={6}
      side="bottom">
      {renderContent()}
    </DropdownContainer>
  )
}

AmazonAccountDropdown.defaultProps = {
  isMobile: false,
  onCtaClick: () => {}
}

AmazonAccountDropdown.propTypes = {
  isMobile: PropTypes.bool,
  onCtaClick: PropTypes.func
}
